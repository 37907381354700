import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import { useTokens } from '../hooks/useTokens';

function Dashboard() {
  const { userName, logout } = useAuth();
  const { tokens, generateToken, isApiAvailable } = useTokens();
  const [copySuccess, setCopySuccess] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [requestDetails, setRequestDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const nameMatch = userName.match(/^([^@]+)/);
    if (nameMatch) {
      setDisplayName(nameMatch[1]);
    }
  }, [userName]);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const makeAuthenticatedRequest = async () => {
    if (tokens.length === 0) {
      setError("No token available. Please generate a token first.");
      return;
    }

    setIsLoading(true);
    setError(null);
    setApiResponse(null);

    const endpoint = 'https://api.py-api.com/execute';
    const requestBody = {
      code: `
import requests

def get_joke():
    response = requests.get("https://official-joke-api.appspot.com/random_joke")
    if response.status_code == 200:
        joke = response.json()
        return f"{joke['setup']} ... {joke['punchline']}"
    else:
        return "Failed to fetch a joke. Here's a backup: Why don't scientists trust atoms? Because they make up everything!"

print("Welcome to the Random Joke Generator!")
print(get_joke())
`,
    requirements: ["requests"]
  };

    const headers = {
      'Authorization': `Bearer ${tokens[0].fullValue}`,
      'Content-Type': 'application/json',
    };

    console.log('Full request details:', {
      url: endpoint,
      method: 'POST',
      headers: headers,
      data: requestBody
    });

    setRequestDetails({
      endpoint,
      method: 'POST',
      headers: {
        ...headers,
        'Authorization': `Bearer ${tokens[0].value}...` // Mask the full token in the display
      },
      body: JSON.stringify(requestBody, null, 2),
    });

    try {
      const response = await axios.post(endpoint, requestBody, { headers });
      console.log('Full response:', response);
      setApiResponse(response.data);
    } catch (error) {
      console.error("Full error object:", error);
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        setError(`Error ${error.response.status}: ${error.response.data || 'No error message provided'}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError("No response received from the server. Please try again later.");
      } else {
        console.error('Error setting up the request:', error.message);
        setError(`Error: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <div className="logo">Py-api</div>
      </header>
      <div className="dashboard-content">
        <h1 className="welcome-message">Welcome <span role="img" aria-label="waving hand">👋</span> {displayName}!</h1>
        <div className="profile-info">
          <h3>Profile Information</h3>
          <p>Email: {userName}</p>
        </div>
        <div className="token-management">
          <h3>Your Token</h3>
          <div className="token-container">
            {tokens.length > 0 ? (
              <div className="token-display">
                <span className="token-value">{tokens[0].value}</span>
                <button
                  className={`copy-button ${copySuccess ? 'success' : ''}`}
                  onClick={() => copyToClipboard(tokens[0].fullValue)}
                >
                  {copySuccess ? 'Copied!' : 'Copy'}
                </button>
              </div>
            ) : (
              <button className="fetch-button" onClick={generateToken}>Fetch Token</button>
            )}
          </div>
        </div>
        <div className="api-test">
          <h3>API Test</h3>
          <button
            onClick={makeAuthenticatedRequest}
            disabled={isLoading || tokens.length === 0 || !isApiAvailable}
          >
            {isLoading ? 'Testing...' : 'Test API'}
          </button>
          {isLoading && <p>Loading...</p>}
          {error && (
            <div className="error-message">
              <h4>Error:</h4>
              <p>{error}</p>
            </div>
          )}
          {requestDetails && (
            <div className="request-details">
              <h4>HTTP Request Details:</h4>
              <pre>
                {`${requestDetails.method} ${requestDetails.endpoint}
Headers:
${JSON.stringify(requestDetails.headers, null, 2)}

Body:
${requestDetails.body}`}
              </pre>
            </div>
          )}
          {apiResponse && (
            <div className="api-response">
              <h4>API Response:</h4>
              <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
            </div>
          )}
        </div>
      </div>
      <footer className="dashboard-footer">
        <div className="logout-container">
          <span className="logout-text" onClick={logout}>Logout</span>
        </div>
        <div className="legal-links">
          <a href="/privacy-policy">Privacy Policy</a>
          <span className="separator">|</span>
          <a href="/terms-of-use">Terms of Use</a>
        </div>
      </footer>
    </div>
  );
}

export default Dashboard;
