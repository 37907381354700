import React, { useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { register, Hanko } from "@teamhanko/hanko-elements";
import './HankoAuth.css';

const hankoApi = process.env.REACT_APP_HANKO_API_URL || "https://c119528e-bc8f-4787-bb12-3fbd46e31ac3.hanko.io";

export default function HankoAuth({ onAuthSuccess }) {
  const navigate = useNavigate();
  const hanko = useMemo(() => new Hanko(hankoApi), []);

  const redirectAfterLogin = useCallback(async () => {
    console.log("Login successful, redirecting");
    try {
      onAuthSuccess();
      navigate("/dashboard");
    } catch (error) {
      console.error("Error in redirectAfterLogin:", error);
      navigate("/login");
    }
  }, [navigate, onAuthSuccess]);

  useEffect(() => {
    console.log("HankoAuth component mounted");
    
    const setupHanko = async () => {
      try {
        await register(hankoApi);
        console.log("Hanko registered successfully");

        const hankoAuth = document.querySelector('hanko-auth');
        if (hankoAuth) {
          console.log("hanko-auth element found, adding event listeners");
          hankoAuth.addEventListener('success', redirectAfterLogin);
        } else {
          console.warn("hanko-auth element not found");
        }

        // Set up session created listener
        const unsubscribe = hanko.onSessionCreated(() => {
          console.log("Session created, calling redirectAfterLogin");
          redirectAfterLogin();
        });

        return () => {
          if (hankoAuth) {
            console.log("Removing success event listener");
            hankoAuth.removeEventListener('success', redirectAfterLogin);
          }
          unsubscribe();
        };
      } catch (error) {
        console.error("Error setting up Hanko:", error);
      }
    };

    setupHanko();
  }, [hanko, redirectAfterLogin]);

  return <hanko-auth />;
}