import React from 'react';
import styled from 'styled-components';
import HankoAuth from './HankoAuth';

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0a0b1a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  max-width: 400px;
  width: 100%;

  /* These styles can be removed or commented out as they're now in HankoAuth.css */
  /*
  .hanko_loadingSpinnerWrapper,
  .hanko_loadingSpinnerWrapperIcon {
    display: inline-flex;
    align-items: center;
    height: 100%;
    margin: 0 8px;
    justify-content: center;
  }

  .hanko_loadingSpinnerWrapperIcon {
    column-gap: 10px;
  }
  */
`;



const AuthPopup = ({ onAuthSuccess }) => {
  return (
    <PopupOverlay>
      <PopupContent>
        <HankoAuth onAuthSuccess={onAuthSuccess} />
      </PopupContent>
    </PopupOverlay>
  );
};

export default AuthPopup;



