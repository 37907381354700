import React, { useState, useRef, useEffect } from 'react';

function CodeExample() {
  const [activeTab, setActiveTab] = useState('curl');
  const [showResponse, setShowResponse] = useState(false);
  const [responseText, setResponseText] = useState('');
  const responseRef = useRef(null);

  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  const toggleResponse = () => {
    setShowResponse(!showResponse);
    if (!showResponse) {
      setResponseText('');
      typeResponse();
    }
  };

  const typeResponse = () => {
    const text = `# API Response:
{
  "output": "[0.12345678 0.23456789 0.34567890 0.45678901 0.56789012]",
  "execution_time": 0.0054,
  "status": "success"
}`;
    let i = 0;
    const typeWriter = () => {
      if (i < text.length) {
        setResponseText(prev => prev + text.charAt(i));
        i++;
        setTimeout(typeWriter, 20);
      }
    };
    typeWriter();
  };

  useEffect(() => {
    if (showResponse && responseRef.current) {
      responseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showResponse]);

  const renderCodeContent = (content) => ({
    __html: content
  });

  return (
    <>
      <div className="code-box">
        <div className="code-header">
          <div className="tabs">
            <button className={`tab ${activeTab === 'curl' ? 'active' : ''}`} onClick={() => switchTab('curl')}>curl</button>
            <button className={`tab ${activeTab === 'python' ? 'active' : ''}`} onClick={() => switchTab('python')}>Python</button>
            <button className={`tab ${activeTab === 'javascript' ? 'active' : ''}`} onClick={() => switchTab('javascript')}>JavaScript</button>
          </div>
          <button className="run-code" onClick={toggleResponse}>Run code</button>
        </div>
        <div id="curl" className={`code-content ${activeTab === 'curl' ? 'active' : ''}`}>
          <pre><code dangerouslySetInnerHTML={renderCodeContent(`<span class="keyword">curl</span> -X POST https://api.py-api.com/execute \\
  -H <span class="string">"Authorization": "your-api-key"</span> \\
  -d '{
    <span class="string">"code"</span>: <span class="string">"import numpy as np\\nprint(np.random.rand(5))"</span>,
    <span class="string">"requirements"</span>: [<span class="string">"numpy"</span>]
  }'`)} />
          </pre>
        </div>
        <div id="python" className={`code-content ${activeTab === 'python' ? 'active' : ''}`}>
          <pre><code dangerouslySetInnerHTML={renderCodeContent(`<span class="keyword">import</span> requests

url = <span class="string">"https://api.py-api.com/execute"</span>
payload = {
    <span class="string">"code"</span>: <span class="string">"import numpy as np\\nprint(np.random.rand(5))"</span>,
    <span class="string">"requirements"</span>: [<span class="string">"numpy"</span>]
}

response = requests.post(url, json=payload)
print(response.json())`)} />
          </pre>
        </div>
        <div id="javascript" className={`code-content ${activeTab === 'javascript' ? 'active' : ''}`}>
          <pre><code dangerouslySetInnerHTML={renderCodeContent(`<span class="keyword">fetch</span>(<span class="string">'https://api.py-api.com/execute'</span>, {
  method: <span class="string">'POST'</span>,
  headers: {
    <span class="string">'Authorization'</span>: <span class="string">'your-api-key'</span>
  },
  body: JSON.stringify({
    code: <span class="string">"import numpy as np\\nprint(np.random.rand(5))"</span>,
    requirements: [<span class="string">"numpy"</span>]
  })
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error(<span class="string">'Error:'</span>, error));`)} />
          </pre>
        </div>
      </div>
      {showResponse && (
        <div id="response" className="code-box" ref={responseRef}>
          <pre><code dangerouslySetInnerHTML={renderCodeContent(responseText)} />
        </pre>
        </div>
      )}
    </>
  );
}

export default CodeExample;