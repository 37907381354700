import { useState, useCallback, useEffect } from 'react';
import { useAuth } from './useAuth';

export function useTokens() {
  const [tokens, setTokens] = useState([]);
  const { hanko } = useAuth();

  const maskToken = (token) => {
    if (token.length <= 10) {
      return token;
    }
    return token.slice(0, 10) + '*'.repeat(token.length - 10);
  };

  const updateToken = useCallback((jwt) => {
    if (jwt) {
      const maskedToken = maskToken(jwt);
      setTokens([{ value: maskedToken, fullValue: jwt }]);
      console.log("Updated Hanko JWT token:", maskedToken);
    } else {
      console.log("No JWT token found");
      setTokens([]);
    }
  }, []);

  const fetchTokens = useCallback(async () => {
    console.log("Fetching Hanko JWT token...");
    try {
      const session = await hanko.session.get();
      if (session) {
        updateToken(session.jwt);
      } else {
        console.log("No active session found");
        setTokens([]);
      }
    } catch (error) {
      console.error("Error fetching Hanko JWT token:", error);
      setTokens([]);
    }
  }, [hanko, updateToken]);

  useEffect(() => {
    fetchTokens();

    const unsubscribe = hanko.onSessionCreated(() => {
      console.log("Session created, fetching new token");
      fetchTokens();
    });

    return () => {
      unsubscribe();
    };
  }, [hanko, fetchTokens]);

  const generateToken = useCallback(() => {
    console.log("Generate token called, fetching Hanko JWT");
    fetchTokens();
  }, [fetchTokens]);

  return {
    tokens,
    generateToken,
    isApiAvailable: true
  };
}
