import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Hanko } from "@teamhanko/hanko-elements";

const hankoApi = process.env.REACT_APP_HANKO_API_URL;
export function useAuth() {
  const [userName, setUserName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  
  const hanko = useMemo(() => new Hanko(hankoApi), []);

  const checkAuthStatus = useCallback(async () => {
    console.log("Checking auth status");
    setIsLoading(true);
    setError(null);
    try {
      const session = await hanko.session.get();
      if (session) {
        console.log("Session found, user is authenticated");
        setIsAuthenticated(true);
        const user = await hanko.user.getCurrent();
        setUserName(user.email);
        console.log("User email:", user.email);
      } else {
        console.log("No session found, user is not authenticated");
        setIsAuthenticated(false);
        if (location.pathname !== '/' && location.pathname !== '/login') {
          console.log("Redirecting to login");
          navigate('/login');
        }
      }
    } catch (error) {
      console.error("Error checking auth status:", error);
      setIsAuthenticated(false);
      setError("Authentication error. Please try logging in again.");
      navigate('/login');
    } finally {
      setIsLoading(false);
    }
  }, [hanko, navigate, location]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const onAuthSuccess = useCallback(() => {
    console.log("onAuthSuccess called");
    setIsAuthenticated(true);
    checkAuthStatus();
  }, [checkAuthStatus]);

  const logout = useCallback(async () => {
    try {
      await hanko.user.logout();
      setIsAuthenticated(false);
      setUserName('');
      navigate('/dashboard');
      // Prevent immediate auth check
      setTimeout(() => checkAuthStatus(), 100);
    } catch (error) {
      console.error("Error during logout:", error);
      setError("Logout failed. Please try again.");
    }
  }, [hanko, navigate, checkAuthStatus]);

  return { isAuthenticated, isLoading, error, userName, onAuthSuccess, logout, hanko };
}