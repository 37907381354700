import React from 'react';
import { useNavigate } from 'react-router-dom';
import CodeExample from './CodeExample';

function Home() {
  const navigate = useNavigate();

  const handleGetApiKey = () => {
    navigate("/login");
  };

  return (
    <div className="container">
      <h1>Py-api.com</h1>
      <p>Execute Python code via API. Instant. Scalable. Any library.</p>
      
      <h2>&gt; Example</h2>
      <CodeExample />
      
      <button onClick={handleGetApiKey} className="button">Get API Key</button>
      
      <footer>
        &copy; 2024 py-api.com | <a href="#terms">Terms</a> | <a href="#privacy">Privacy</a>
      </footer>
    </div>
  );
}

export default Home;