import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPopup from './components/AuthPopup';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import ErrorBoundary from './components/directory/ErrorBoundary';
import { useAuth } from './hooks/useAuth';
import { useTokens } from './hooks/useTokens';
import './App.css';

function AppContent() {
  const { isAuthenticated, isLoading, error, userName, onAuthSuccess } = useAuth();
  const { tokens, generateToken, deleteToken, isApiAvailable } = useTokens();

  console.log("App render - isAuthenticated:", isAuthenticated);
  console.log("App render - userName:", userName);
  console.log("App render - tokens:", tokens);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={
          isAuthenticated ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <ErrorBoundary>
              <AuthPopup onAuthSuccess={onAuthSuccess} />
            </ErrorBoundary>
          )
        } />
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? (
              <Dashboard
                userName={userName}
                tokens={tokens}
                generateToken={generateToken}
                deleteToken={deleteToken}
                isApiAvailable={isApiAvailable}
              />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {error && <div className="error-message">{error}</div>}
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;

const API_URL = 'https://api.py-api.com/execute';